export interface EntitlementInterface {
  id: string
  feature: EntitlementFeature
  expires_at: Date
  timeLeft: string
  metadata: {
    state: string | undefined
    productId: string | undefined
  }
  entitlementTransaction: Record<string, unknown> | undefined
}

export enum EntitlementFeature {
  DeleteMessage = "delete_message",
  NewGuys = "new_guys",
  ReadReceipts = "read_receipts",
  RemoveAds = "remove_ads",
  SWCYO = "swcyo",
  UnlockGrid = "unlock_grid",
  UnlimitedPhotos = "unlimited_photos",
  AgeFilter = "age_filter",
  FavouritesFilter = "favourites_filter",
  HeightFilter = "height_filter",
  KysFilter = "kys_filter",
  LookingForFilter = "looking_for_filter",
  OnlineOnlyFilter = "online_filter",
  RelationshipStatusFilter = "relationship_status_filter",
  RoleFilter = "role_filter",
  WeightFilter = "weight_filter",
  Quickies = "hornet_x",
  IdentityVerifiedFilter = "identity_verified_filter",
  DirectVideoCalls = "direct_video_calls",
  ShareExpiringPhoto = "share_expiring_photo",
  GridBoost = "grid_boost",
  UnlimitedPrivatePhotoAccessRequest = "unlimited_private_photo_access_request",
  UnlockExplore = "unlock_explore",
  UnlockNearby = "unlock_nearby",
  EthnicityFilter = "ethnicity_filter",
  LanguageFilter = "language_filter",
  IdentityVerification = "identity_verification",
  VideoBoost = "video_boost",
  DisableScreenshots = "disable_screenshots",
  InvisibleMode = "invisible_mode",
  TypingIndicator = "typing_indicator",
  UnlimitedMessageDelete = "unlimited_message_delete",
  PrivateGalleries = "private_galleries",
}

export enum EntitlementDuration {
  OneDay = "P1D",
  OneWeek = "P1W",
  OneMonth = "P1M",
  ThreeMonths = "P3M",
  HalfYear = "P6M",
  OneYear = "P1Y",
  OneMinute = "PT1M",
  SevenMinutes = "PT7M",
}
