import useTranslation from "next-translate/useTranslation"
import Modal from "./Modal"
import TextButton from "../UI/TextButton"
import styled from "styled-components"
import NiceModal from "@ebay/nice-modal-react"
import { ReactNode } from "react"

const ModalHeading = styled.div`
  ${({ theme }) => theme.font.bold.body};
`

const ModalText = styled.div`
  ${({ theme }) => theme.font.regular.subheadline};
`

type FlashMessageModalProps = {
  text: string | ReactNode
  heading?: string
  okButtonLabel?: string
  retry?: {
    retryLabel: string
    retryAction: () => void
    hideOk?: boolean
  }
}

const StyledModal = styled(Modal)`
  min-width: 0;
  max-width: ${({ theme }) => theme.width.containerMaxWidthMobile};
  padding-bottom: ${({ theme }) => theme.spacing.regular};
`

const FlashMessageModal = NiceModal.create(
  ({ heading, text, okButtonLabel, retry }: FlashMessageModalProps) => {
    const { t } = useTranslation()
    const modal = NiceModal.useModal()

    const handleConfirmOnClick = () => {
      modal.resolve()
      void modal.hide()
    }

    const handleRetryActionOnClick = async () => {
      modal.resolve()
      void modal.hide()

      // give a little break so it's not instant (if for example there's a being-offline issue)
      if (retry) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        retry.retryAction()
      }
    }

    const isHidingOkButton = retry?.hideOk === true

    return (
      <StyledModal
        open={modal.visible}
        locked={false}
        onClose={modal.hide}
        isFullscreen={false}
        hasCloseButton={false}
        actions={
          <ActionsWrapper>
            {!isHidingOkButton && (
              <TextButton
                size={"small"}
                primary={true}
                onClick={handleConfirmOnClick}
              >
                {okButtonLabel ||
                  t("core:components.modals.flash_message_modal.buttons.ok")}
              </TextButton>
            )}

            {retry ? (
              <TextButton size={"small"} onClick={handleRetryActionOnClick}>
                {retry.retryLabel}
              </TextButton>
            ) : null}
          </ActionsWrapper>
        }
      >
        {heading && <ModalHeading>{heading}</ModalHeading>}
        <ModalText>{text}</ModalText>
      </StyledModal>
    )
  }
)

export default FlashMessageModal

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.half};
`
