import { ApiService } from "./API"
import {
  debug,
  Either,
  makeLeft,
  makeRight,
} from "@hornet-web-react/core/utils"
import {
  Country,
  defaultServerSessionAnalytics,
  hasPremium,
  LoggedInUser,
  LoginApiPayload,
  MinimalSessionApiPayload,
  Otp,
  ServerSessionAnalytics,
} from "@hornet-web-react/core/types/session"
import ForbiddenError from "./API/Errors/ForbiddenError"
import TeapotMessageError from "./API/Errors/TeapotMessageError"
import NotFoundError from "./API/Errors/NotFoundError"
import CustomApiError from "./API/Errors/CustomApiError"
import UnprocessableEntityError from "./API/Errors/UnprocessableEntityError"
import { ApiServiceEndpoint } from "./API/ApiServiceEndpoint"
import { format } from "date-fns"
import { HornetProfileId } from "@hornet-web-react/core/types"

export type SuccessfulLoginPayload = LoggedInUser

export enum LoginError {
  ServerError,
  RequireCaptcha,
  AccountNotFound,
  AccountSuspended,
  TeapotMessageError,
  InvalidCredentials,
}

export type LoginResult = Either<
  {
    error: LoginError
    errorTitle?: string
    errorMessage?: string
  },
  SuccessfulLoginPayload
>

type LoginViaEmailPayload = {
  session: {
    id: string
    secret: string
    provider: "Hornet"
  }
  "g-recaptcha-response"?: string
}

type LoginViaFacebookPayload = {
  session: {
    secret: string
    provider: "Facebook"
  }
  "g-recaptcha-response"?: string
}

type LoginViaGooglePayload = {
  session: {
    secret: string
    provider: "Google"
  }
  "g-recaptcha-response"?: string
}

type LoginViaQuickiesUdidPayload = {
  session: {
    id: string
    provider: "QuickiesUDID"
    ipq: string // IPQ base64 encoded result
    date_of_birth: string // YYYY-MM-DD
    location_randomization_amount: number
  }
}

class LoginService {
  private readonly _apiService: ApiService

  constructor(apiService: ApiService) {
    debug(`LoginService: constructor`)

    this._apiService = apiService
  }

  async otpLogin(
    otp: Otp,
    isMinimal = true,
    attempt = 1
  ): Promise<LoginResult> {
    debug(`LoginService: otpLogin(otp, attempt: ${attempt})`)

    try {
      if (isMinimal) {
        const minimalSessionPayload = await this._apiService.post(
          "/sso_tokens/exchange",
          {
            be_exchange_token: process.env["BE_EXCHANGE_TOKEN"],
            rt: otp,
            session_format: "minimal",
          }
        )

        const minimalSession = MinimalSessionApiPayload.parse(
          minimalSessionPayload
        )
        // console.log(`minimalSession`, minimalSession);

        return makeRight(
          this.transformMinimalSessionToLoginPayload(minimalSession)
        )
      } else {
        const fullSessionPayload = await this._apiService.post(
          "/sso_tokens/exchange",
          {
            be_exchange_token: process.env["BE_EXCHANGE_TOKEN"],
            rt: otp,
          }
        )

        const fullSession = LoginApiPayload.parse(fullSessionPayload)
        // console.log(`fullSession`, fullSession);

        return makeRight(this.transformSessionToLoginPayload(fullSession))
      }
    } catch (error) {
      if (error instanceof ForbiddenError) {
        // retry, maybe the OTP token wasn't available yet
        // we have this issue that sometimes OTP token is not found on the BE
        // and maybe it's because it's not synced yet, so we're going to try
        // a few times with some delays and see if we succeed eventually
        if (attempt <= 2) {
          await new Promise((resolve) => setTimeout(resolve, 500))

          return this.otpLogin(otp, isMinimal, attempt + 1)
        }
      }

      return this.handleLoginError(error)
    }
  }

  async loginViaEmail(
    email: string,
    password: string,
    captchaResponse?: string
  ): Promise<LoginResult> {
    debug(`LoginService: loginViaEmail(${email}, ...)`)

    try {
      const payload: LoginViaEmailPayload = {
        session: {
          id: email,
          secret: password,
          provider: "Hornet",
        },
      }

      if (typeof captchaResponse === "string") {
        payload["g-recaptcha-response"] = captchaResponse
      }

      const sessionApiPayload = await this._apiService.useEndpoint(
        ApiService.getEndpoint(ApiServiceEndpoint.CreateSessionPost),
        payload
      )

      const session = LoginApiPayload.parse(sessionApiPayload)

      return makeRight(this.transformSessionToLoginPayload(session))
    } catch (error) {
      return this.handleLoginError(error)
    }
  }

  async loginViaFacebook(
    authToken: string,
    captchaResponse?: string
  ): Promise<LoginResult> {
    debug(`LoginService: loginViaFacebook(...)`)

    try {
      const payload: LoginViaFacebookPayload = {
        session: {
          secret: authToken,
          provider: "Facebook",
        },
      }

      if (typeof captchaResponse === "string") {
        payload["g-recaptcha-response"] = captchaResponse
      }

      const sessionApiPayload = await this._apiService.useEndpoint(
        ApiService.getEndpoint(ApiServiceEndpoint.CreateSessionPost, []),
        payload
      )

      const session = LoginApiPayload.parse(sessionApiPayload)

      return makeRight(this.transformSessionToLoginPayload(session))
    } catch (error) {
      return this.handleLoginError(error)
    }
  }

  async loginViaGoogle(
    authToken: string,
    captchaResponse?: string
  ): Promise<LoginResult> {
    debug(`LoginService: loginViaGoogle(...)`)

    try {
      const payload: LoginViaGooglePayload = {
        session: {
          secret: authToken,
          provider: "Google",
        },
      }

      if (typeof captchaResponse === "string") {
        payload["g-recaptcha-response"] = captchaResponse
      }

      const sessionApiPayload = await this._apiService.useEndpoint(
        ApiService.getEndpoint(ApiServiceEndpoint.CreateSessionPost, []),
        payload
      )

      const session = LoginApiPayload.parse(sessionApiPayload)

      return makeRight(this.transformSessionToLoginPayload(session))
    } catch (error) {
      return this.handleLoginError(error)
    }
  }

  async loginViaQuickiesUdid(
    udid: string,
    ipqResult: string,
    dateOfBirth: Date,
    locationRandomizationAmount: number
  ): Promise<LoginResult> {
    debug(`LoginService: loginViaQuickiesUdid(${udid}, ...)`)

    try {
      const payload: LoginViaQuickiesUdidPayload = {
        session: {
          id: udid,
          provider: "QuickiesUDID",
          ipq: ipqResult,
          date_of_birth: format(dateOfBirth, "yyyy-MM-dd"),
          location_randomization_amount: locationRandomizationAmount,
        },
      }

      const sessionApiPayload = await this._apiService.useEndpoint(
        ApiService.getEndpoint(ApiServiceEndpoint.CreateSessionPost),
        payload
      )

      const session = LoginApiPayload.parse(sessionApiPayload)

      return makeRight(this.transformSessionToLoginPayload(session))
    } catch (error) {
      return this.handleLoginError(error)
    }
  }

  async validateCurrentAccessToken(): Promise<SuccessfulLoginPayload> {
    debug(`LoginService: validateCurrentAccessToken()`)

    const sessionApiPayload = await this._apiService.get("/session")

    const session = LoginApiPayload.parse(sessionApiPayload)

    return this.transformSessionToLoginPayload(session)
  }

  private handleLoginError(error: unknown) {
    console.error(error)

    if (error instanceof ForbiddenError) {
      return makeLeft({
        error: LoginError.AccountSuspended,
      })
    }

    if (error instanceof NotFoundError) {
      return makeLeft({
        error: LoginError.AccountNotFound,
      })
    }

    if (error instanceof UnprocessableEntityError) {
      return makeLeft({
        error: LoginError.InvalidCredentials,
      })
    }

    if (error instanceof CustomApiError && [429, 445].includes(error.status)) {
      return makeLeft({
        error: LoginError.RequireCaptcha,
      })
    }

    if (error instanceof TeapotMessageError) {
      return makeLeft({
        error: LoginError.TeapotMessageError,
        errorTitle: error.teapotTitle,
        errorMessage: error.teapotMessage,
      })
    }

    if (error instanceof Error) {
      return makeLeft({
        error: LoginError.ServerError,
        errorMessage: error.message,
      })
    }

    return makeLeft({ error: LoginError.ServerError })
  }

  public transformSessionToLoginPayload({
    session,
  }: LoginApiPayload): SuccessfulLoginPayload {
    const loginPayload = this.transformMinimalSessionToLoginPayload({ session })

    // currently, only "hornet_x" entitlement is something of value for us (in both
    // Hornet and Quickies) so let's drop the rest so we save some cookie space
    const entitlements = (session.entitlements ?? []).filter(
      (e) => e.entitlement.feature === "hornet_x"
    )

    const serverSessionAnalytics = ServerSessionAnalytics.safeParse(
      session.settings?.analytics
    )

    return {
      isAuthenticated: true,
      currentUser: {
        ...loginPayload.currentUser,
        hasActiveQuickiesProfile:
          session.quickies !== undefined &&
          session.quickies.quickies_profile.active,
        entitlements,
        serverSessionAnalytics: serverSessionAnalytics.success
          ? serverSessionAnalytics.data
          : defaultServerSessionAnalytics,
      },
    }
  }

  private transformMinimalSessionToLoginPayload({
    session,
  }: MinimalSessionApiPayload): SuccessfulLoginPayload {
    // create custom token expiration
    const tokenExpiry = new Date()
    tokenExpiry.setHours(tokenExpiry.getHours() + 2)

    // this is expiration from the server
    const serverTokenValidUntil = new Date(session.valid_until)

    // whichever is earlier, use it for expiration
    const accessTokenValidUntil =
      serverTokenValidUntil < tokenExpiry ? serverTokenValidUntil : tokenExpiry

    const currentCountryCode = Country.safeParse(
      session.profile.current_country_code
        ? String(session.profile.current_country_code).toLowerCase()
        : null
    )

    return {
      isAuthenticated: true,
      currentUser: {
        accessToken: String(session.access_token),
        accessTokenValidUntil: accessTokenValidUntil.toISOString(),
        username: String(session.account.username),
        email: session.account.email ? String(session.account.email) : null,
        hasPremium: hasPremium(session.account),
        profileId: HornetProfileId.parse(session.profile.id),
        currentCountryCode: currentCountryCode.success
          ? currentCountryCode.data
          : null,
        // TODO: resolve with SSO exchange (maybe not worth it)
        // isImperialUnitOfMeasure:
        //   Number(session.profile.unit_of_measure?.id) === 1,
        isImperialUnitOfMeasure: false,
        hasActiveQuickiesProfile: false,
        isNew: session.account.new_user ?? false,
        entitlements: [],

        // TODO: for better Hornet tracking, either opt-out of Minimal or add
        //  the `session.settings.analytics` to minimal response (BE)
        serverSessionAnalytics: defaultServerSessionAnalytics,
      },
    }
  }
}

export default LoginService
